import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mlInput'
})
export class MlInputPipe implements PipeTransform {

  transform(value: number): number {
    let result = 0;
    if(value <= 10) {
      result = value / 10;
    } else if(value <= 18) {
      result = 1 + (value - 10) * 0.5;
    } else {
      result = 5 + (value - 18);
    }
    return result;
  }

}
