<div class="flex px-5 gap-2 font-semibold">
  <!-- <div class="w-44 py-3 border-b-4 border-ffred text-center">{{ title | translate}}</div> -->
  <div class="w-44 py-3 border-b-4 border-ffred text-center">
    <ng-select
    [items]="languages"
    bindLabel="name"
    bindValue="code"
    [(ngModel)]="selectedLanguage"
    (change)="onLanguageChange($event)"
    placeholder="Select Your Language"
    class="custom-ng-select"
    [searchable]="false"
    [hideSelected]="true"
    [clearable]="false"
  >
    </ng-select>
  </div>

  <div
    [ngClass]="
      'flex m-0 py-2 text-white items-center grow' +
      (isDrawing ? '' : ' opacity-0 pointer-events-none')
    "
  >
    <div
      class="w-full flex flex-wrap gap-2 justify-between"
      role="group"
      aria-label="Basic radio toggle button group"
    >
      <div
        [ngClass]="
          'cursor-pointer grow py-2 rounded-lg text-center ' +
          (filterMode === index ? 'bg-ffred' : 'bg-black')
        "
        matTooltipShowDelay="200"
        matTooltipPosition="below"
        [matTooltip]="'Show ' + button.name + ' picture'"
        *ngFor="let button of filterButtons; index as index"
        (click)="onFilterChoose(index)"
      >
        <div class="" [class.active]="button.isActive">
          {{ button.name | translate}}
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  color="rgba(137,54,129,1)"
  bdColor="rgba(51,51,51,1)"
  type="square-jelly-box"
  [fullScreen]="true"
  name="move-spinner"
>
  <p style="font-size: 30px">
    {{ "topBar.movingToRecommendation" | translate }}
  </p>
</ngx-spinner>
<ngx-spinner
  color="rgba(137,54,129,1)"
  bdColor="rgba(51,51,51,0.8)"
  type="ball-clip-rotate"
  [fullScreen]="true"
  name="save-spinner"
>
  <p style="font-size: 30px">{{ "topBar.savingImage" | translate }}</p>
</ngx-spinner>
