export class DrawingConstant {
    // Filter strength
    public static REDNESS_STRENGTH = 1.6
    public static EVENESS_STRENGTH = 1.0
    public static UV_STRENGTH = 0.25

    // Canvas
    public static MAX_ZOOM = 50
    public static TARGET_FIND_TOLERANCE = 20
    public static BACKGROUND_CROP_COLOR = 'rgba(255,0,0,0.3)'

    // Product
    public static DEFAULT_COLOR = '#893681'
    public static DEFAULT_FONTSIZE = 40
    public static DEFAULT_LINEWIDTH = 5
    public static DEFAULT_HLWIDTH = 40
    public static DEFAULT_HLOPACITY = 0.5
    public static DEFAULT_INJECTIONS_RADIUS = 10

    //Redness Saturation Scale
    public static SATURATION_STRENGTH = 450;
    public static MEANS_COEFFICIENT = 0.12;
    public static MEANS_THRESHOLD = 0.2683
    
}

export class ClientType {
    public static MEESMA = "meesma"
    public static HUB = "hub"
}

export class MessageType {
    public static CONNECTED ="connected"
    public static READY = "ready"
    public static EDITED = "edited"
    public static ORIGINAL = "original"
    public static MANUAL_DISCONNECT = "manual-disconnect"
    public static JOIN = "join"
    public static JOINED = "joined" 
}