import { Component, EventEmitter, Output, Input, OnInit, OnDestroy } from '@angular/core';
import { Product } from '../../models/product';
import { ProductCategory } from '../../models/product-category';
import { REPORT_LAYOUTS } from 'src/app/utils/items';
import { DataService } from '../../../shared/services/data.service';
import { firstValueFrom, lastValueFrom, Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { HubImage, ImageService, Patient } from '../../../shared/services/image.service';
import { ModalService } from '../../../shared/services/modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-recommendation',
  templateUrl: './recommendation.component.html',
  styleUrls: ['../image-editor/image-editor.component.css', './recommendation.component.css']
})
export class RecommendationComponent implements OnInit, OnDestroy {

  categoryList: ProductCategory[] = [];
  categoryMap = new Map<string, ProductCategory>()
  productList: Product[] = []
  reportLayouts = REPORT_LAYOUTS
  reviewingProduct!: Product | null
  isProductEditing = false
  isShowDefault = true
  patient!: Patient
  isProductProcessing = false
  translations: { [key: string]: string } = {};
  _onLangChangeSub!: Subscription;

  @Input() imageData!: string
  @Input() isPrint!: boolean
  @Input() image!: HubImage

  @Output() isPrintChange: EventEmitter<boolean> = new EventEmitter();

  constructor(private dataService: DataService, private _spinner: NgxSpinnerService, private _modalService: ModalService, private _imageService: ImageService,
    private _translate: TranslateService
  ) {
    this._translate.get(["shared.yes", "recommendation.deleteCategoryConfirmation", "recommendation.defaultProductsNotUpdated", "recommendation.categoryDeleted",
    "recommendation.confirm", "recommendation.productEdited", "recommendation.failedToEditCategory", "recommendation.successfullyEditCategory", "recommendation.productCreated",
    "recommendation.categoryExists",
    "recommendation.editMode",
    "recommendation.failedToCreateReport",
    "recommendation.popupsBlocked",
    "recommendation.selectPrintLayout",
    "recommendation.selected",
    "recommendation.preview",
    "recommendation.products",
    "recommendation.loadingMessage",
    "recommendation.productDeleted"
    ]).subscribe((translations) => {
        this.translations = translations;
      })
  }
  ngOnDestroy(): void {
    if(this._onLangChangeSub){
      this._onLangChangeSub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this._getProducts();
    this._onLangChangeSub = this._translate.onLangChange.subscribe(() => {
      this._translate.get(["shared.yes", "recommendation.deleteCategoryConfirmation", "recommendation.defaultProductsNotUpdated", "recommendation.categoryDeleted",
      "recommendation.confirm", "recommendation.productEdited", "recommendation.failedToEditCategory", "recommendation.successfullyEditCategory", "recommendation.productCreated",
      "recommendation.categoryExists",
      "recommendation.editMode",
      "recommendation.failedToCreateReport",
      "recommendation.popupsBlocked",
      "recommendation.selectPrintLayout",
      "recommendation.selected",
      "recommendation.preview",
      "recommendation.products",
      "recommendation.loadingMessage",
      "recommendation.productDeleted"]).subscribe((translations) => {
         this.translations = translations;
       })
    })
  }


  // Private methods

  // Methods

  // Event
  onToggleShowDefault() {
    this.isShowDefault = !this.isShowDefault
  }

  async onCreateReport(event: Event, index: number) {
    try {
      this._spinner.show('report-spinner');
      if(!this.image || !this.image.patient_uuid) {
        console.log('No patient')
      } else {
        try {
          this.patient = await lastValueFrom(this._imageService.getPatient(this.image.patient_uuid))
        } catch (err) {
          //Todo:
        }
      }
      const products: string[] = [];
      this.productList.forEach(p => {
        if(p.selected) products.push(p._id)
      })
      const blob = await (await fetch(this.imageData)).blob();
      const file: File = new File([blob], 'reportImage', {type: blob.type});
      const res = await firstValueFrom(this.dataService.exportReport(file, products, {...this.patient}, index))
      const w = window.open(`/pdf?uuid=${res.uuid}`)
      if(!w) {
        this._modalService.openNotification(this.translations["recommendation.popupsBlocked"])
      }
    } catch (err) {
      this._modalService.openNotification(this.translations["recommendation.failedToCreateReport"])
    } finally {
      this._spinner.hide('report-spinner')
    }
  }

  onToggleEditingMode() {
    this.isProductEditing = !this.isProductEditing
  }

  onPrintClosed() {
    this.isPrintChange.emit(false);
  }

  onPreviewImageLoaded(event: Event) {
    event.preventDefault();
    this._spinner.hide('review-spinner')
  }

  onProductClicked(ev: Event, product: Product) {
    if(this.isProductEditing) {
      this._modalService.openNotification(this.translations["recommendation.editMode"])
    } else {
      this.onProductReviewed(ev, product)
    }
  }

  onProductReviewed(event: Event, product: Product) {
    if(this.reviewingProduct?._id == product._id) {
      return
    }
    this._spinner.show('review-spinner')
    this.reviewingProduct = product;
  }


  refreshComponent() {
    if(this.isProductProcessing) return
    this._getProducts()
  }

  onCreateCategory() {
    this._modalService.openCategoryForm()
      .then(result => {
        if(result) {
          if(this.categoryMap.has(result)) {
            this._modalService.openNotification(this.translations["recommendation.categoryExists"])
          } else {
            const c = new ProductCategory(result)
            this.categoryList.push(c)
            this.categoryMap.set(c.category, c)
          }
        }
      })
  }

  onCreateProduct(event: Event, category: string) {
    this._modalService.openProductForm('CREATE PRODUCT', category)
      .then(result => {
        if(result) {
          this._modalService.openNotification(this.translations["recommendation.productCreated"])
          const p = result as Product
          this.productList.push(p)
          this._refreshReview()
          this._arrangeProducts()
        }
      })
  }

  onEditCategory(ev: Event, category: ProductCategory) {
    this._modalService.openConfirm(this.translations["recommendation.confirm"],
      this.translations["recommendation.defaultProductsNotUpdated"])
      .then(() => {
        this._modalService.openCategoryForm(category.category)
        .then((result) => {
          if(result && result != category.category) {
            this._spinner.show('product-spinner')
            lastValueFrom(this.dataService.putCategory(category.category, result))
              .then(() => {
                this.categoryMap.get(category.category)?.products.forEach(p => { if(!p.isDefault) p.category == result })
                this._arrangeProducts()
                this._modalService.openNotification(this.translations["recommendation.successfullyEditCategory"])
              }).catch((err) => {
                err.message()
                this._modalService.openNotification(this.translations["recommendation.failedToEditCategory"])
              }).finally(() => {
                this._spinner.hide('product-spinner')
              })
          }
        }).catch((err) => {
          err.message();
        });
      })
      .catch()
  }

  onEditProduct(event: Event, product: Product) {
    this._modalService.openProductForm('EDIT PRODUCT', undefined, product)
      .then(result => {
        if(result) {
          this._spinner.show('product-spinner')
          this._modalService.openNotification(this.translations["recommendation.productEdited"])
          const p = result as Product
          product.category = p.category
          product.name = p.name
          product.description = p.description
          product.price1 = p.price1
          product.price1desc = p.price1desc
          product.price2 = p.price2
          product.price2desc = p.price2desc
          product.isDefault = p.isDefault
          if(p.url) product.url = p.url
          this._arrangeProducts()
          this._refreshReview()
          this._spinner.hide('product-spinner')
        }
      })
  }

  onProductTypeSelected(event: MouseEvent, category: ProductCategory) {
    this.categoryList.forEach(c => {
      if(c.category == category.category)
        c.selected = !c.selected
      else c.selected = false
    })

  }

  onProductSelected(event: MouseEvent, product: Product) {
    product.selected = true
  }

  onProductDeselected(event: MouseEvent, product: Product) {
    product.selected = false
  }

  deleteCategory(_event: MouseEvent, data: ProductCategory) {
    _event.stopPropagation();
    if(!data.products || data.products.length < 1) {
      this.categoryMap.delete(data.category)
      this.categoryList = this.categoryList.filter(c => c.category != data.category)
      this._modalService.openNotification('recommendation.categoryDeleted')
    } else {
      this._modalService.openConfirm(this.translations["recommendation.confirm"], this.translations["recommendation.deleteCategoryConfirmation"], this.translations["shared.yes"], this.translations["shared.cancel"])
        .then(result => {
          if (result) {
            this._spinner.show('product-spinner')
            lastValueFrom(this.dataService.deleteCategory(data.category))
              .then(() => {
                this.productList = this.productList.filter(p => p.category != data.category)
                this._refreshReview()
                this._modalService.openNotification(this.translations["recommendation.categoryDeleted"])
                this._spinner.hide('product-spinner')
              }).catch(() => {
                this._spinner.hide('product-spinner')
              })
          }
        })
    }
  }

  deleteProduct(_event: MouseEvent, data: Product) {
    _event.stopPropagation();
    this._modalService.openConfirm(this.translations["recommendation.confirm"], this.translations["recommendation.deleteCategoryConfirmation"], this.translations["Yes"], this.translations["Cancel"])
      .then(result => {
        if(result) {
          this.isProductProcessing = true
          this._spinner.show('product-spinner')
          lastValueFrom(this.dataService.deleteProduct(data._id))
            .then(() => {
              this.productList = this.productList.filter(p => p._id != data._id)
              this._arrangeProducts()
              this._spinner.hide('product-spinner')
              this._modalService.openNotification(this.translations["recommendation.productDeleted"])
              this._refreshReview()
            }).catch(err => {
              err.message()
              this._spinner.hide('product-spinner')
            }).finally(() => {
              this.isProductProcessing = false
            })
        }
      })
  }

  private _refreshReview() {
    this.reviewingProduct = null
  }

  private _getProducts() {
    this.isProductProcessing = true
    this._spinner.show('product-spinner')
    lastValueFrom(this.dataService.getProducts())
      .then(res => {
        this.productList = res.payload.products
        this._arrangeProducts()
        this._spinner.hide('product-spinner')
      })
      .catch(err => {
        console.log(err)
        this._spinner.hide('product-spinner')
      }).finally(() => {
        this.isProductProcessing = false
      })
  }

  private _arrangeProducts() {
    this.categoryList = []
    this.categoryMap.clear()
    this.productList.forEach(p => {
      if(!this.categoryMap.has(p.category)) {
        const c = new ProductCategory(p.category);
        this.categoryList.push(c)
        this.categoryMap.set(p.category, c);
      }
      this.categoryMap.get(p.category)?.products.push(p)
    })
    this.categoryList.forEach(c => {
      c.products.sort((a, b) => a.name > b.name? 1: -1)
    })
  }
}

