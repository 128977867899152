<div class="flex flex-col w-full grow bg-black text-white">
  <div class="flex grow w-full gap-4 portrait:gap-0 portrait:flex-col-reverse px-5">
    <!-- side bar -->
    <div
      class="w-44 portrait:w-full shrink-0 flex flex-col justify-end text-white h-full portrait:h-36 gap-4"
    >
      <div
        *ngIf="canShowSlider"
        class="flex flex-col portrait:flex-row items-center p-3 rounded-lg gap-2 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 shadow-md"
      >
        <input
          type="checkbox"
          id="originalImageCheckbox"
          class="hidden"
          [ngClass]="onOriginalImageSlider ? 'active' : ''"
          (change)="handleOriginalImageSlider()"
        />
        <label for="originalImageCheckbox" class="flex items-center gap-2">
          <div
            class="w-5 h-5 bg-gray-200 rounded-full flex items-center justify-center transition-all duration-300 cursor-pointer"
          >
            <span
              *ngIf="onOriginalImageSlider"
              class="block w-3 h-3 bg-purple-600 rounded-full"
            ></span>
          </div>
          <p class="text-sm font-semibold cursor-pointer">
            {{ "faceSimulation.originalImage" | translate }}
          </p>
        </label>
      </div>

      <div class="flex flex-col items-center portrait:flex-row portrait:justify-between">
        <!-- 3 slider -->
        <div class="portrait:flex portrait:gap-6">
          <div class="flex flex-col items-center">
            <label>{{ "faceSimulation.upperLip" | translate }} : {{ _upperVal * 5 }}</label>
            <div class="flex w-fit items-center gap-4">
              <span>0</span>
              <mat-slider
                [(ngModel)]="_upperVal"
                min="0"
                max="1"
                step="0.2"
                tickInterval="0.2"
                [displayWith]="formatSliderValue"
                id="slider-upper-invisible"
                [disabled]="!sliderVisible"
                name="upperLipSlider"
                ngDefaultControl
                style="width: 100px"
              >
                <input
                  matSliderThumb
                  value="{{ _upperVal }}"
                  #sliderThumbUpper
                  (valueChange)="onValueChangedUpper()"
                />
              </mat-slider>
              <span>5</span>
            </div>
          </div>

          <div class="flex flex-col items-center">
            <label>{{ "faceSimulation.lowerLip" | translate }} : {{ _lowerVal * 5 }}</label>
            <div class="flex w-fit items-center gap-4">
              <span>0</span>
              <mat-slider
                [(ngModel)]="_lowerVal"
                min="0"
                max="1"
                step="0.2"
                tickInterval="0.2"
                [displayWith]="formatSliderValue"
                id="slider-lower-invisible"
                [disabled]="!sliderVisible"
                name="lowerLipSlider"
                ngDefaultControl
                style="width: 100px"
              >
                <input
                  matSliderThumb
                  value="{{ _lowerVal }}"
                  #sliderThumbLower
                  (valueChange)="onValueChangedLower()"
                />
              </mat-slider>
              <span>5</span>
            </div>
          </div>

          <div class="flex flex-col items-center">
            <label>{{ "faceSimulation.eyebrows" | translate }} : {{ _eyesBrowVal * 5 }}</label>
            <div class="flex w-fit items-center gap-4">
              <span>0</span>
              <mat-slider
                [(ngModel)]="_eyesBrowVal"
                min="0"
                max="1"
                step="0.2"
                tickInterval="0.2"
                [displayWith]="formatSliderValue"
                id="slider-for-eyesBrow"
                [disabled]="!sliderVisible"
                name="eyesBrowSlider"
                ngDefaultControl
                style="width: 100px"
              >
                <input
                  matSliderThumb
                  value="{{ _eyesBrowVal }}"
                  #sliderForEyesBrow
                  (valueChange)="onValueChangedForEyesBrow()"
                />
              </mat-slider>
              <span>5</span>
            </div>
          </div>
        </div>
        <!-- 3slider -->
        <div class="flex flex-wrap justify-evenly gap-2 pt-5 portrait:pt-0">
          <div
            [ngClass]="
              (onVertical ? 'active' : '') +
              ' flex flex-col items-center p-1 rounded gap-1 cursor-pointer hover:bg-ffred'
            "
            (click)="onVerticalClick()"
          >
            <img
              src="../../../../assets/img/svg/vertical.svg"
              alt=""
              class="w-4"
            />
            <p class="text-xs">{{ "faceSimulation.vertical" | translate }}</p>
          </div>
          <div
            [ngClass]="
              (onHorizontal ? 'active' : '') +
              ' flex flex-col items-center p-1 rounded gap-1 cursor-pointer hover:bg-ffred'
            "
            (click)="onHorizontalClick()"
          >
            <img
              src="../../../../assets/img/svg/horizontal.svg"
              alt=""
              class="w-4"
            />
            <p class="text-xs">{{ "faceSimulation.horizontal" | translate }}</p>
          </div>
          <div
            class="flex flex-col items-center gap-1 p-1 rounded cursor-pointer hover:bg-ffred"
            (click)="resetImg()"
          >
            <img
              src="../../../../assets/img/svg/refresh.svg"
              alt=""
              class="w-4"
            />
            <p class="text-xs">{{ "faceSimulation.reset" | translate }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- content -->
    <div
      class="w-full h-full flex portrait:gap-1 landscape:gap-2"
    >
      <div *ngIf="isHub" class="landscape:w-16 portrait:w-22 shrink-0"></div>
      <div
        class="bg-ffgray border-2 rounded-lg border-white w-full h-full flex gap-4"
      >
        <div class="w-full h-full flex-col gap-2">
          <div
            #canvasBound
            id="faceImageCanvasContainer"
            (mouseup)="onContainerMouseUp($event)"
            (mousemove)="onContainerMouseMove($event)"
            (touchend)="onContainerTouchEnd()"
            (touchmove)="onContainerTouchMove($event)"
            class="slider-wrapper w-full h-full flex justify-center items-center overflow-hidden"
          >
            <div
              *ngIf="onOriginalImageSlider"
              class="slider z-[1000]"
              (touchstart)="onSliderTouchStart($event)"
              (mousedown)="onSliderMouseDown($event)"
              [style.left]="'min(' + sliderLeft + '%' + ', calc(100% - 3px))'"
            >
              <span>
                <i class="fa-sharp fa-solid fa-less-than"></i>
                <i class="fa-sharp fa-solid fa-greater-than"></i>
              </span>
            </div>
            <img
              style="object-fit: contain"
              #targetImage
              src="{{ url }}"
              crossorigin="anonymous"
              id="face-image"
              class="absolute max-h-full"
            />
          </div>
        </div>
      </div>
      <div *ngIf="isHub" class="landscape:w-16 portrait:w-22 shrink-0"></div>
    </div>
  </div>

  <div id="fps" class="hidden"></div>
  <!-- footer -->
</div>
<ngx-spinner
  color="rgba(137,54,129,1)"
  bdColor="#262b31"
  name="face-load"
  size="medium"
  type="ball-clip-rotate"
  [fullScreen]="true"
>
  <p style="font-size: 30px; font-weight: bold">
    {{ "faceSimulation.analyzing" | translate }}
  </p>
</ngx-spinner>
<div
  class="fixed right-0 left-0 top-0 bottom-0 bg-ffblack z-[100]"
  *ngIf="!isEnabled"
></div>
