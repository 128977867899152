import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, inject } from '@angular/core';
import { fabric } from 'fabric';
import cloneDeep from 'lodash.clonedeep';
import { Fabric } from './cross';
import { DrawingConstant } from 'src/app/utils/const';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserActionService } from 'src/app/shared/services/user-action.service';



@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class CanvasComponent implements OnInit {
  // private attr
  private _canvas: any
  private _drawingMode = {
    move: false,
    select: false,
    arrow: false,
    blackRect: false,
    blackCir: false,
    area: false,
    text: false,
    highlight: false,
    free: false,
    cross: false,
    point: false,
    crop: false
  }
  private _touchZoom = {
    lastX1: -1,
    lastX2: -1,
    y: -1,
    x: -1,
    zooming: false
  }
  private _mouseEvent = {
    down: false,
  }
  private _arrowSelectedColor = 'red'
  private _currentObject: fabric.Object[] = []
  private _cropRect!: fabric.Rect
  private _cropBg!: fabric.Rect
  private _backgroundImg!: fabric.Image
  private _overlayImg!: fabric.Image | null
  private _ratio!: number
  private _spinner = inject(NgxSpinnerService)
  private _lineSize!: number
  private _fontSize!: number
  private _highlightSize!: number
  private _highlightOpacity!: number
  private _drawingColor!: string
  private _injectionsRadius!: number

  @Output() private _disableDrawing: EventEmitter<boolean> = new EventEmitter<boolean>()
  // public attr
  unit!: string
  value!: number
  undo: fabric.Object[][] = []
  redo: fabric.Object[][] = []
  isCropped = false
  isImageLoaded = false
  @Output() isImageLoadedChange: EventEmitter<boolean> = new EventEmitter()
  @Input() set drawingColor(value: string) {
    this._drawingColor = value
    this._canvas && (this._canvas.freeDrawingBrush.color = this._drawingMode.highlight? this._hexToRgbA(value, this.highlightOpacity) : this._hexToRgbA(value, 1))
  }
  get drawingColor() {  return this._drawingColor }
  @Input() set lineSize(value: number) {
    this._lineSize = value
    if(this._canvas && (this._drawingMode.area || this._drawingMode.free)) this._canvas.freeDrawingBrush.width = value;
  }
  get lineSize() {  return this._lineSize }

  @Input() set fontSize(value: number) {
    this._fontSize = value
  }

  get fontSize() {  return this._fontSize }

  @Input() set injectionsRadius(value: number) {
    this._injectionsRadius = value
  }

  get injectionsRadius() {  return this._injectionsRadius }

  @Input() set highlightSize(value: number) {
    this._highlightSize = value
    if(this._canvas && this._drawingMode.highlight) this._canvas.freeDrawingBrush.width = value;
  }
  get highlightSize() {  return this._highlightSize }


  @Input() set highlightOpacity(value: number) {
    this._highlightOpacity = value
    if(this._canvas && this._drawingMode.highlight) this._canvas.freeDrawingBrush.color = this._hexToRgbA(this.drawingColor, value)
  }
  get highlightOpacity() {  return this._highlightOpacity }

  // hook events

  constructor(private _userAction: UserActionService) {
    _userAction.imageFit$.subscribe((value) => {
      if(this._canvas && this._backgroundImg && value) {
        this._canvas.viewportTransform = [1, 0, 0, 1, 0, 0]
        this._canvas.renderAll()
      }
    })
  }

  ngOnInit(): void {
    this._canvas = new fabric.Canvas('canvas-editor', {
      width: 1,
      height: 1,
      backgroundColor: '#262b31',
      hoverCursor: 'cursor',
      moveCursor: 'move',
      selectionColor: 'transparent',
      selectionDashArray: [5,5],
      selectionBorderColor: '#fff',
      selectionKey: 'ctrlKey',
      selectionLineWidth: 3,
      selection: false,
      uniformScaling: false,
      notAllowedCursor: 'cursor',
      defaultCursor: 'default',
      targetFindTolerance: DrawingConstant.TARGET_FIND_TOLERANCE,
      skipTargetFind: false,
      stateful: false,
      enableRetinaScaling: true,
      perPixelTargetFind: true,
      allowTouchScrolling: false,
      stopContextMenu: true
    });
    this._canvas.freeDrawingBrush.limitedToCanvasSize = true;
    this._objectPrototypeInit();
    this._eventInit();
  }

  // public methods
  get isCropping() {
    return this._drawingMode.crop;
  }

  hasChanged() {
    if(!this._canvas) return false;
    return (this.isCropped || this.objectCount > 0)
  }

  get objectCount() {
    return this._canvas.getObjects().filter((o: fabric.Object) => o.name != "overlay" || o.width).length;
  }

  resizeCanvas(height: number, width: number, event: MouseEvent | TouchEvent) {
    this._canvas.viewportTransform = [1, 0, 0, 1, 0, 0];
    if(this._backgroundImg) {
      if(this._ratio - (height / width) >= 0) {
        this._backgroundImg.scaleToHeight(height);
        this._canvas.setWidth(this._backgroundImg.width as number * (this._backgroundImg.scaleY as number));
        this._canvas.setHeight(height);
      } else {
        this._backgroundImg.scaleToWidth(width);
        this._canvas.setHeight(this._backgroundImg.height as number * (this._backgroundImg.scaleX as number));
        this._canvas.setWidth(width);
      }
      if(this._overlayImg) {
        this._overlayImg.scaleToHeight(this._canvas.height);
        this.modifyOverlayImage(event);
      }
    }
    this._canvas.renderAll();
  }

  changeBackground(src: string) {
    this._backgroundImg.setSrc(src, () => {
      this._canvas.renderAll()
      this._spinner.hide('filter-spinner')
    }, {
      crossOrigin: 'anonymous'
    })
  }
  resetCanvas() {
    this.undo = [];
    this.redo = [];
    this._canvas.clear();
  }

  exportImage(): string {
    let image = '';
    const options = {
      format: 'jpeg',
      multiplier: 1 / (this._backgroundImg?.scaleX || 1),
      quality: 0.95,
    }
    if(this.isCropped && this._cropRect) {
      this._canvas.remove(this._cropRect);
      Object.assign(options, {
        left: this._cropRect.left,
        top: this._cropRect.top,
        width: this._cropRect.width,
        height: this._cropRect.height
      })
      image = this._canvas.toDataURL(options)
      this._canvas.add(this._cropRect);
    } else {
      const vpt = [...this._canvas.viewportTransform]
      this._canvas.viewportTransform = [1, 0, 0, 1, 0, 0]
      image = this._canvas.toDataURL(options)
      this._canvas.viewportTransform = vpt
    }
    return image;
  }

  browseImage(url: string, height: number, width: number) {
    //this.resetCanvas();
    fabric.Image.fromURL(url, (img: any) => {
      this._spinner.hide('filter-spinner')
      if(img._element) {
        this.isImageLoadedChange.emit(true)
      } else {
        return
      }
      // resize image to fit device screen
      this._backgroundImg = img;
      this._ratio = img.height / img.width;
      if(this._ratio - (height / width) >= 0) {
        img.scaleToHeight(height)
        const scaleX = img.scaleX
        this._canvas.setWidth(img.width * scaleX)
        this._canvas.setHeight(height)
        this._canvas.setDimensions({
          height: `${height}px`,
          width: `${Math.floor(img.width * scaleX)}px`
        }, {cssOnly: true});
      } else {
        img.scaleToWidth(width)
        const scaleY = img.scaleY
        this._canvas.setHeight(img.height * scaleY)
        this._canvas.setWidth(width)
        this._canvas.setDimensions({
          width: `${width}px`,
          height: `${Math.floor(img.height * scaleY)}px`
        }, {cssOnly: true});
      }
      this._canvas.setBackgroundImage(img, this._canvas.renderAll.bind(this._canvas), {
      });
    }, {
      crossOrigin: "anonymous"
    })
  }

  removeOverlayImage() {
    if(this._overlayImg) {
      this._canvas.remove(this._overlayImg);
      this._overlayImg = null;
    }
  }

  setOverlayImage(url: string, ev: MouseEvent | TouchEvent) {
    let p: {
      x: number,
      y: number
    };
    this.removeOverlayImage();
    if(ev) {
      p = this._canvas.getPointer(ev);
    } else {
      p = {
        x: this._canvas.getWidth() / 2,
        y: 0
      };
    }
    fabric.Image.fromURL(url, (img: fabric.Image) => {
      this._overlayImg = img;
      img.scaleToHeight(this._canvas.height);
      if(p.x > 0) {
        const w = p.x / (img.scaleX as number)
        this._overlayImg?.set({
          width: w
        })
      } else {
        this._overlayImg?.set({
          width: 0
        })
      }
      this._canvas.add(this._overlayImg);
      this._canvas.sendToBack(this._overlayImg);
    }, {
      name: 'overlay',
      hasBorders: false,
      hasControls: false,
      selectable: false,
      hoverCursor: 'default'
    })
  }

  modifyOverlayImage(ev: MouseEvent | TouchEvent) {
    if(!ev || !this._overlayImg) return;
    const p = this._canvas.getPointer(ev);
    if(p.x > 0) {
      const l = p.x / (this._overlayImg.scaleX as number)
      this._overlayImg?.set({
        width: l
      })
      this._overlayImg?.setCoords();
      this._canvas.renderAll();
    } else {
      this._overlayImg?.set({
        width: 0
      })
      this._overlayImg?.setCoords();
      this._canvas.renderAll();
    }
  }

  undoCanvas() {
    if(this._drawingMode.crop) return;
    if(this.undo.length == 0) return;
    this.redo.push(cloneDeep(this._currentObject));
    this._currentObject = cloneDeep(this.undo.pop() as fabric.Object[]);
    // this.redo.push(this._currentObject);
    // this._currentObject = this.undo.pop();
    this._resetObject();
  }

  redoCanvas() {
    if(this._drawingMode.crop) return;
    if(this.redo.length == 0) return;
    this.undo.push(cloneDeep(this._currentObject));
    this._currentObject = cloneDeep(this.redo.pop() as fabric.Object[]);
    // this.undo.push(this._currentObject);
    // this._currentObject = this.redo.pop();
    this._resetObject();
  }

  uncrop() {
    this._canvas.remove(this._cropBg)
    this._canvas.remove(this._cropRect)
    this.isCropped = false
  }

  resetBrush() {
    this._canvas.freeDrawingBrush.color = this._hexToRgbA(this.drawingColor, 1);
    if(this._drawingMode.free || this._drawingMode.area) {
      this._canvas.freeDrawingBrush.width = this.lineSize;
    } else if(this._drawingMode.highlight) {
      this._canvas.freeDrawingBrush.width = this.highlightSize;
      this._canvas.freeDrawingBrush.color = this._hexToRgbA(this.drawingColor, this.highlightOpacity);
    }
  }

  selectMode(value: number) {
    this._drawingMode.move = false;
    this._drawingMode.arrow = false;
    this._drawingMode.blackRect = false;
    this._drawingMode.blackCir = false;
    this._drawingMode.text = false;
    this._drawingMode.area = false;
    this._drawingMode.free = false;
    this._drawingMode.highlight = false;
    this._drawingMode.select = false;
    this._drawingMode.cross = false;
    this._drawingMode.point = false;
    this._drawingMode.crop = false;
    this._canvas.selectable = false;
    this._canvas.selection = false;
    this._canvas.isDrawingMode = false;
    this._preventInterativity(false);
    this._defMode(value);
  }

  // Private method
  private _hexToRgbA(hex: string, alpha: number){
    let c: any;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c = hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',') +', ' + alpha + ')';
    }
    return 'black';
}

  private _objectPrototypeInit() {
    fabric.Object.prototype.hasBorders = false;
    fabric.Object.prototype.cornerColor = 'red';
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerSize = 10;
    fabric.Object.prototype.moveCursor = 'move';
    fabric.Object.prototype.hoverCursor = 'move';
    fabric.Object.prototype.controls['mtr'].visible = false;
    fabric.Object.NUM_FRACTION_DIGITS = 20;
    fabric.IText.prototype.hasBorders = true;
    fabric.IText.prototype.borderColor = 'transparent';
    fabric.IText.prototype.borderDashArray = [5, 5];

    Fabric.Cross.prototype.hasBorders = false;
    Fabric.Cross.prototype.hasControls = true;
    Fabric.Cross.prototype.objectCaching = false
    Fabric.Cross.prototype.touchCornerSize = 20;
    Fabric.Cross.prototype.controls = {
      'c': new fabric.Control({
        positionHandler: this._moveUnitTextPositionHandler,
        actionHandler: this._moveUnitTextActionHandler
      })
    };


    Fabric.TPoint.prototype.hasBorders = false;
    Fabric.TPoint.prototype.hasControls = true;
    Fabric.TPoint.prototype.objectCaching = false
    Fabric.TPoint.prototype.controls = {
      'c': new fabric.Control({
        positionHandler: this._moveUnitTextPositionHandler,
        actionHandler: this._moveUnitTextActionHandler
      })
    };
  }

  private _defMode(value: number) {
    switch (value) {
      case -1:
      case 0:
        this._drawingMode.select = true;
        break;
      case 1:
        this._canvas.selection = true;
        this._preventInterativity(true);
        break;
      case 2:
        this._canvas.isDrawingMode = true;
        this._drawingMode.highlight = true;
        this.resetBrush();
        this._preventInterativity(true);
        break;
      case 3:
        this._drawingMode.arrow = true;
        this._preventInterativity(true);
        break;
      case 4:
        this._canvas.isDrawingMode = true;
        this._drawingMode.free = true;
        this.resetBrush();
        this._preventInterativity(true);
        break;
      case 5:
        this._drawingMode.area = true;
        this._canvas.isDrawingMode = true;
        this._preventInterativity(true);
        this.resetBrush();
        break;
      case 6:
        this._drawingMode.blackRect = true;
        this._preventInterativity(true);
        break;
      case 7:
        this._drawingMode.blackCir = true;
        this._preventInterativity(true);
        break;
      case 8:
        this._drawingMode.crop = true,
        this._preventInterativity(true);
        break;
      case 9:
        this._drawingMode.text = true;
        this._preventInterativity(true);
        break;
      case 10:
        this._drawingMode.point = true;
        this._preventInterativity(true);
        break;
      case 11:
        this._drawingMode.cross = true;
        this._preventInterativity(true);
        break;
      case 12:
        this._drawingMode.move = true;
        this._preventInterativity(true);
        break;
    }
  }

  private _preventInterativity(value: boolean) {
    this._canvas.skipTargetFind = value;
  }

  private _resetObject() {
    this._canvas._objects = [];
    this._currentObject.forEach((obj: any) => {
      if(obj.name == 'overlay' || obj.name == 'cropBg' || obj.name == 'cropRect') return;
      obj.clone((clone: any) => {
        this._canvas.add(clone);
      },['hasBorders','hasControls'])
    })
    if(this.isCropped && this._cropBg) {
      this._canvas.add(this._cropBg);
      this._canvas.sendToBack(this._cropBg);
    }
    if(this.isCropped && this._cropRect) {
      this._canvas.add(this._cropRect);
      this._canvas.bringToFront(this._cropRect);
    }
    if(this._overlayImg) {
      this._canvas.add(this._overlayImg);
      this._canvas.sendToBack(this._overlayImg);
    }
    this._canvas.discardActiveObject().renderAll();
  }

  private _saveState() {
    this.redo = [];
    this.undo.push(cloneDeep(this._currentObject));
    this._canvas.remove(this._cropBg)
    this._canvas.remove(this._cropRect)
    this._currentObject = cloneDeep(this._canvas.getObjects());
    if(this.isCropped && this._cropBg) {
      this._canvas.add(this._cropBg);
      this._canvas.sendToBack(this._cropBg);
    }
    if(this.isCropped && this._cropRect) {
      this._canvas.add(this._cropRect);
      this._canvas.bringToFront(this._cropRect);
    }
  }

  private _eventInit() {
    let triangle: any;
    let line: any;
    let blackRect: any;
    let blackCir: any;
    let origX: any;
    let origY: any;
    let clipPath!: fabric.Rect
    let moveOriginX: number;
    let moveOriginY: number;

    document.onkeydown = (o: any) => {
      if(this._drawingMode.crop) return;
      switch(o.keyCode) {
        case 46:
          if(this._canvas.getActiveObjects().length == 0) return;
          this._canvas.getActiveObjects().forEach((obj: any) => {
            this._canvas.remove(obj);
          })
          this._canvas.discardActiveObject();
          this._saveState();
          break;
      }
    };

    this._canvas.on('selection:created', (o: any) => {
      o.selected.forEach((obj: any) => {
        if (obj.type === 'arrow') {
          obj._objects[0].set({
            stroke: this._arrowSelectedColor
          });
          obj._objects[1].set({
            fill: this._arrowSelectedColor
          })
        }
      });
    });

    this._canvas.on('selection:updated', (o: any) => {
      o.selected.forEach((obj: any) => {
        if (obj.type === 'arrow') {
          obj._objects[0].set({
            stroke: this._arrowSelectedColor
          });
          obj._objects[1].set({
            fill: this._arrowSelectedColor
          })
        }
      });
      o.deselected.forEach((obj: any) => {
        if (obj.type === 'arrow') {
          obj._objects[0].set({
            stroke: this.drawingColor
          });
          obj._objects[1].set({
            fill: this.drawingColor
          })
        }
      });
    });

    this._canvas.on('selection:cleared', (o: any) => {
      if(!o.deselected) return;
      o.deselected.forEach((obj: any) => {
        if (obj.type === 'arrow') {
          obj._objects[0].set({
            stroke: this.drawingColor
          });
          obj._objects[1].set({
            fill: this.drawingColor
          })
        }
      });
      this.isCropped && this._canvas.bringToFront(this._cropBg);
      this.isCropped && this._canvas.bringToFront(this._cropRect);
    });

    this._canvas.on('path:created', (o: any) => {
      const areaPath = o.path;
      if(this._drawingMode.area) {
        const length = areaPath.path.length;
        const endPoint = [...areaPath.path[0]];
        areaPath.path[length] = ['L', endPoint[1], endPoint[2]];
      }
      this._saveState();
      this._canvas.setActiveObject(areaPath);
      this._canvas.renderAll();
    });

    this._canvas.on('object:modified', (o: any) => {
      const s = o.target;
      let item!: fabric.Object[]
      if(s instanceof fabric.ActiveSelection) {
        item = s.getObjects();
        s.ungroupOnCanvas();
        s._objects = [];
      }
      this._saveState();
      if(s instanceof fabric.ActiveSelection) {
        item.forEach((obj) => {
          s.addWithUpdate(obj)
        })
      }
    });

    this._canvas.on('mouse:down', (o: any) => {
      this._mouseEvent.down = true;
      const pointer = this._canvas.getPointer(o.e);
      if (this._drawingMode.arrow) {
        const points = [pointer.x, pointer.y, pointer.x, pointer.y];
        line = new fabric.Line(points, {
          strokeWidth: this.lineSize,
          fill: 'transparent',
          stroke: this._arrowSelectedColor,
          originX: 'center',
          originY: 'center',
          hasBorders: false,
          hasControls: false
        });
        const centerX = (line.x1 + line.x2) / 2;
        const centerY = (line.y1 + line.y2) / 2;
        const deltaX = line.left - centerX;
        const deltaY = line.top - centerY;

        triangle = new fabric.Triangle({
          left: line.get('x1') + deltaX,
          top: line.get('y1') + deltaY,
          originX: 'center',
          originY: 'center',
          angle: -45,
          width: this.lineSize * 4,
          height: this.lineSize * 6,
          fill: this._arrowSelectedColor
        });
        this._canvas.add(line, triangle);
        this._canvas.setActiveObject(line, triangle);
      } else if (this._drawingMode.blackRect) {
        origX = pointer.x;
        origY = pointer.y;
        blackRect = new fabric.Rect({
          left: origX,
          top: origY,
          width: pointer.x - origX,
          height: pointer.y - origY,
        })
        this._canvas.add(blackRect);
        this._canvas.setActiveObject(blackRect);
      } else if (this._drawingMode.blackCir) {
        origX = pointer.x;
        origY = pointer.y;
        blackCir = new fabric.Ellipse({
          left: origX,
          top: origY,
          originX: 'left',
          originY: 'top',
          rx: pointer.x - origX,
          ry: pointer.y - origY,
          angle: 0,
        });
        this._canvas.add(blackCir);
        this._canvas.setActiveObject(blackCir);
      } else if (this._drawingMode.text) {
        const text: fabric.IText = new fabric.IText('', {
          left: pointer.x,
          top: pointer.y,
          fill: this.drawingColor,
          fontSize: this.fontSize
        });
        this._canvas.add(text);
        this._canvas.setActiveObject(text);
        text.enterEditing();
        this._disableDrawing.emit(true);
      } else if (this._drawingMode.cross) {
        const cross = new Fabric.Cross([], {
          lineWidth: this._injectionsRadius / 4,
          radius: this._injectionsRadius,
          left: pointer.x - this._injectionsRadius,
          top: pointer.y - this._injectionsRadius,
          stroke: this.drawingColor,
          fontSize: this.fontSize,
          text: this.value + ' ' + this.unit,
          fill: this.drawingColor
        })
        this._canvas.add(cross);
        this._canvas.setActiveObject(cross);
      } else if (this._drawingMode.point) {
        const point = new Fabric.TPoint([], {
          radius: this._injectionsRadius,
          left: pointer.x - this._injectionsRadius,
          top: pointer.y - this._injectionsRadius,
          fontSize: this.fontSize,
          text: this.value + ' ' + this.unit,
          fill: this.drawingColor
        })
        this._canvas.add(point);
        this._canvas.setActiveObject(point);
      } else if (this._drawingMode.crop) {
        this._canvas.remove(this._cropBg);
        this._canvas.remove(this._cropRect);
        this._cropBg = new fabric.Rect({
          left: 0, top: 0,
          width: this._canvas.width,
          height: this._canvas.height,
          hasBorders: false,
          hasControls: false,
          selectable: false,
          fill: DrawingConstant.BACKGROUND_CROP_COLOR,
          name: 'cropBg'
        });
        origX = pointer.x;
        origY = pointer.y;
        this._cropRect = new fabric.Rect({
          left: origX,
          top: origY,
          width: pointer.x - origX,
          height: pointer.y - origY,
          hasBorders: false,
          hasControls: false,
          stroke: 'red',
          strokeWidth: 3,
          strokeDashArray: [10, 5],
          fill: 'transparent',
          selectable: false,
          name: 'cropRect',
        })
        this._canvas.add(this._cropBg, this._cropRect);
        this._canvas.setActiveObject(this._cropRect);
        this.isCropped = true
      } else if (this._drawingMode.move) {
        moveOriginX = pointer.x;
        moveOriginY = pointer.y;
        this._canvas.defaultCursor = 'move';
      }
    });

    this._canvas.on('mouse:move', (o: any) => {
      if (!this._mouseEvent.down) return;
      const pointer = this._canvas.getPointer(o.e);
      if (this._drawingMode.arrow) {
        line.set({
          x2: pointer.x,
          y2: pointer.y
        });
        triangle.set({
          'angle': this._FabricCalArrowAngle(line.x1, line.y1, line.x2, line.y2)
        });
      } else if (this._drawingMode.blackRect) {
        if (origX > pointer.x) {
          blackRect.set({ left: Math.abs(pointer.x) });
        }
        if (origY > pointer.y) {
          blackRect.set({ top: Math.abs(pointer.y) });
        }
        blackRect.set({ width: Math.abs(origX - pointer.x) });
        blackRect.set({ height: Math.abs(origY - pointer.y) });
      } else if (this._drawingMode.blackCir) {
        let rx = Math.abs(origX - pointer.x)/2;
        let ry = Math.abs(origY - pointer.y)/2;
        if (rx > blackCir.strokeWidth) {
            rx -= blackCir.strokeWidth/2;
        }
        if (ry > blackCir.strokeWidth) {
            ry -= blackCir.strokeWidth/2;
        }
        blackCir.set({ rx: rx, ry: ry});

        if(origX > pointer.x){
            blackCir.set({originX: 'right' });
        } else {
            blackCir.set({originX: 'left' });
        }
        if(origY > pointer.y){
            blackCir.set({originY: 'bottom'  });
        } else {
            blackCir.set({originY: 'top'  });
        }
      } else if (this._drawingMode.cross) {
        const cross: any = this._canvas.getActiveObject();
        const text: any = cross._objects[2] as fabric.Text;
        cross.removeWithUpdate(text);
        text.clone((clone: fabric.Text) => {
          clone.set({
            left: pointer.x,
            top: pointer.y
          });
          cross.addWithUpdate(clone);
        });
      } else if (this._drawingMode.point) {
        const tpoint: any = this._canvas.getActiveObject();
        const text: fabric.Text = tpoint._objects[1] as fabric.Text;
        tpoint.removeWithUpdate(text);
        text.set({
          left: pointer.x,
          top: pointer.y
        });
        tpoint.addWithUpdate(text);
      } else if (this._drawingMode.crop) {
        const x = pointer.x < 0? 0 : pointer.x > this._canvas.width? this._canvas.width : pointer.x;
        const y = pointer.y < 0? 0 : pointer.y > this._canvas.height? this._canvas.height : pointer.y;
        if (origX > x) {
          this._cropRect?.set({ left: Math.abs(x) });
        }
        if (origY > pointer.y) {
          this._cropRect?.set({ top: Math.abs(y) });
        }
        this._cropRect?.set({ width: Math.abs(origX - x) });
        this._cropRect?.set({ height: Math.abs(origY - y) });
        clipPath = new fabric.Rect({
          left: this._cropRect?.left,
          top: this._cropRect?.top,
          width: this._cropRect?.width,
          height: this._cropRect?.height,
          inverted: true,
          absolutePositioned: true
        });
        this._cropBg.clipPath = clipPath;
        this._canvas.renderAll();
      } else if (this._drawingMode.select) {
        if(window.TouchEvent && o.e instanceof TouchEvent) {
          const t = o.e as TouchEvent;
          if(t.touches.length == 2 && t.changedTouches.length == 2) {
            const a = this._canvas.getPointer(t.touches.item(0));
            this._canvas._resetTransformEventData();
            const b = this._canvas.getPointer(t.touches.item(1));
            if(!this._touchZoom.zooming) {
              this._touchZoom.lastX1 = a.x;
              this._touchZoom.lastX2 = b.x;
              this._touchZoom.y = (a.y + b.y)/2;
              this._touchZoom.x = (a.x + b.x)/2;
              this._touchZoom.zooming = true;
              return;
            } else {
              if((this._touchZoom.lastX1 < a.x && this._touchZoom.lastX2 < b.x) || (this._touchZoom.lastX1 < b.x && this._touchZoom.lastX2 < a.x) ||
              (this._touchZoom.lastX1 > a.x && this._touchZoom.lastX2 > b.x) || (this._touchZoom.lastX1 > b.x && this._touchZoom.lastX2 > a.x)) return;
              const deltaX = Math.abs(this._touchZoom.lastX1 - this._touchZoom.lastX2) - Math.abs(a.x - b.x);
              if(Math.abs(deltaX) < 25) return;
              this._touchZoom.lastX1 = a.x;
              this._touchZoom.lastX2 = b.x;
              let zoom = this._canvas.getZoom();
              zoom *= 0.997 ** (deltaX);
              if (zoom > DrawingConstant.MAX_ZOOM) zoom = DrawingConstant.MAX_ZOOM;
              if (zoom < 1) zoom = 1;
              this._canvas.zoomToPoint({x: this._touchZoom.x, y: this._touchZoom.y}, zoom);
              this._userAction.announceZoomChanged(Math.round(zoom*100))
              o.e.preventDefault();
              o.e.stopPropagation();
              const vpt = this._canvas.viewportTransform;
              if (zoom < 1) {
                vpt[4] = 200 - 1000 * zoom / 2;
                vpt[5] = 200 - 1000 * zoom / 2;
              } else {
                if (vpt[4] >= 0) {
                  vpt[4] = 0;
                } else if (vpt[4] < this._canvas.getWidth() * (1 - zoom)) {
                  vpt[4] = this._canvas.getWidth() * (1 - zoom);
                }
                if (vpt[5] >= 0) {
                  vpt[5] = 0;
                } else if (vpt[5] < this._canvas.getHeight() * (1 - zoom)) {
                  vpt[5] = this._canvas.getHeight() * (1 - zoom);
                }
              }
            }
          }
        }
      } else if (this._drawingMode.move) {
        const zoom = this._canvas.getZoom();
        if(zoom == 1) return;
        const diffX = pointer.x - moveOriginX;
        const diffY = pointer.y - moveOriginY;
        const vpt = this._canvas.viewportTransform;
        vpt[4] += diffX;
        vpt[5] += diffY;
        if (vpt[4] >= 0) {
          vpt[4] = 0;
        } else if (vpt[4] < this._canvas.getWidth() * (1 - zoom)) {
          vpt[4] = this._canvas.getWidth() * (1 - zoom);
        }
        if (vpt[5] >= 0) {
          vpt[5] = 0;
        } else if (vpt[5] < this._canvas.getHeight() * (1 - zoom)) {
          vpt[5] = this._canvas.getHeight() * (1 - zoom);
        }
      }
      this._canvas.renderAll();
    });

    this._canvas.on('mouse:up', (o: any) => {
      this._mouseEvent.down = false;
      if (this._drawingMode.arrow) {
        const group = new window.fabric.Group([line, triangle],
          {
            type: 'arrow',
            perPixelTargetFind: true,
            hasBorders: false,
            hasControls: false
          }
        );
        this._canvas.remove(line, triangle);// removing old object
        this._canvas.add(group);
        this._canvas.setActiveObject(group);
        this._saveState();
      } else if (this._drawingMode.blackRect) {
        const tmp = blackRect;
        this._canvas.remove(blackRect);
        this._canvas.add(tmp);
        this._canvas.setActiveObject(tmp);
        this._saveState();
      } else if (this._drawingMode.blackCir) {
        const tmp = blackCir;
        this._canvas.remove(blackCir);
        this._canvas.add(tmp);
        this._canvas.setActiveObject(tmp);
        this._saveState();
      } else if (this._drawingMode.cross || this._drawingMode.point) {
        this._saveState();
      } else if (this._drawingMode.crop) {
        //Todo:
      } else if (this._drawingMode.select) {
        if(window.TouchEvent && o.e instanceof TouchEvent) {
          this._touchZoom.zooming = false;
        }
      } else if (this._drawingMode.move) {
        this._canvas.defaultCursor = 'default';
      }
    });

    this._canvas.on('mouse:out', () => {
      if(this._canvas.isDrawingMode)
        this._canvas._resetTransformEventData();
    });

    this._canvas.on('dragover', (o: any) => {
      o.e.preventDefault();
    });

    this._canvas.on('drop', (o: any) => {
      o.e.preventDefault();
      const value = o.e.dataTransfer.getData('text');
      const pointer = this._canvas.getPointer(o.e);
      const text: fabric.IText = new fabric.IText(value, {
        left: pointer.x,
        top: pointer.y,
        fill: this.drawingColor,
        fontSize: this.fontSize
      })
      this._canvas.add(text);
      this._canvas.setActiveObject(text);
      this._saveState();
    });

    this._canvas.on('mouse:wheel', (o: any) => {
      const delta = o.e.deltaY;
      let zoom = this._canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > DrawingConstant.MAX_ZOOM) zoom = DrawingConstant.MAX_ZOOM;
      if (zoom < 1) zoom = 1;
      const pointer = this._canvas.getPointer(o.e)
      this._canvas.zoomToPoint({ x: pointer.x, y: pointer.y }, zoom);
      this._userAction.announceZoomChanged(Math.round(zoom*100))
      o.e.preventDefault();
      o.e.stopPropagation();
      const vpt = this._canvas.viewportTransform;
      if (zoom < 1) {
        vpt[4] = 200 - 1000 * zoom / 2;
        vpt[5] = 200 - 1000 * zoom / 2;
      } else {
        if (vpt[4] >= 0) {
          vpt[4] = 0;
        } else if (vpt[4] < this._canvas.getWidth() * (1 - zoom)) {
          vpt[4] = this._canvas.getWidth() * (1 - zoom);
        }
        if (vpt[5] >= 0) {
          vpt[5] = 0;
        } else if (vpt[5] < this._canvas.getHeight() * (1 - zoom)) {
          vpt[5] = this._canvas.getHeight() * (1 - zoom);
        }
      }
    });

    this._canvas.on('touch:gesture', (e: any) => {
      console.log(e)
    })
  }

  private _FabricCalArrowAngle(x1: number, y1: number, x2: number, y2: number) {
    let angle = 0;
    const x = (x2 - x1);
    const y = (y2 - y1);
    if (x === 0) {
      angle = (y === 0) ? 0 : (y > 0) ? Math.PI / 2 : Math.PI * 3 / 2;
    } else if (y === 0) {
      angle = (x > 0) ? 0 : Math.PI;
    } else {
      angle = (x < 0) ? Math.atan(y / x) + Math.PI :
        (y < 0) ? Math.atan(y / x) + (2 * Math.PI) : Math.atan(y / x);
    }
    return (angle * 180 / Math.PI - 90);
  }

  private _moveUnitTextActionHandler(eventData: MouseEvent, transform: fabric.Transform, x: number, y: number) {
    const obj: any = transform.target;
    const text: any = obj.type == 'cross'? obj._objects[2] as fabric.Text: obj._objects[1] as fabric.Text;
    obj.removeWithUpdate(text);
    text.set({
      left: x,
      top: y
    });
    text.setCoords();
    obj.addWithUpdate(text);
    return true;
  }

  private _moveUnitTextPositionHandler(dim: {x: number, y: number}, finalMatrix: any, fabricObject: any): fabric.Point {
    const centerPoint = fabricObject.getCenterPoint();
    const text = fabricObject.type == 'cross'? fabricObject._objects[2] as fabric.Text: fabricObject._objects[1] as fabric.Text;
    let point = new fabric.Point(centerPoint.x + (text? text.left: 0), centerPoint.y + (text? text.top: 0));
    if(fabricObject.canvas) {
      point = fabric.util.transformPoint(point, fabricObject.canvas.viewportTransform);
    }
    return point;
  }
}
