import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from '../../proposer/components/modals/confirm-dialog/confirm-modal.component';
import { CreateProductModalComponent } from '../../proposer/components/modals/create-product/create-product-modal.component';
import { CreateCategoryComponent } from '../../proposer/components/modals/create-category/create-category.component';
import { AlertModalComponent } from '../../proposer/components/modals/alert-modal/alert-modal.component';
import { Product } from '../../proposer/models/product';
import { CreateTreatmentComponent } from '../../proposer/components/modals/create-treatment/create-treatment.component';
import { TranslateService } from '@ngx-translate/core';




@Injectable()
export class ModalService {
    ngxOKText!: string;
    ngxCancelText!: string;
    constructor(private modalService: NgbModal, private _translate: TranslateService) {
        this._translate.get("Cancel").subscribe((value: string) => {
            this.ngxCancelText = value;
          });
          this._translate.get("OK").subscribe((value: string) => {
            this.ngxOKText = value;
          });
     }

    public openConfirm(
        title: string,
        message: string,
        btnOkText: string = this.ngxOKText,
        btnCancelText: string = this.ngxCancelText,
        dialogSize: 'sm' | 'lg' = 'sm'): Promise<boolean> {
        const modalRef = this.modalService.open(ConfirmComponent, { size: dialogSize, centered: true, animation: true, modalDialogClass: 'proposer-modal' });
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.btnOkText = btnOkText;
        modalRef.componentInstance.btnCancelText = btnCancelText;

        return modalRef.result;
    }

    public openNotification(
        message: string,
        dialogSize: 'sm' | 'lg' = 'sm'): Promise<boolean> {
        const modalRef = this.modalService.open(AlertModalComponent, { size: dialogSize, centered: true, animation: true, modalDialogClass: 'proposer-modal' });
        modalRef.componentInstance.message = message;
        return modalRef.result;
    }

    public openCategoryForm(
        category?: string,
        dialogSize: 'sm' | 'lg' = 'sm'): Promise<any> {
        const modalRef = this.modalService.open(CreateCategoryComponent, { size: dialogSize, centered: true, animation: true, modalDialogClass: 'proposer-modal'  });
        category && (modalRef.componentInstance.category = category)
        return modalRef.result;
    }

    public openTreatmentForm(title: string, name?: string,
        dialogSize: 'sm' | 'lg' = 'sm'): Promise<any> {
        const modalRef = this.modalService.open(CreateTreatmentComponent, { size: dialogSize, centered: true, animation: true, modalDialogClass: 'proposer-modal'  });
        modalRef.componentInstance.treatment = name
        modalRef.componentInstance.title = title
        return modalRef.result;
    }

    public openProductForm(
        title: string,
        category?: string,
        product?: Product,
        dialogSize: 'sm' | 'lg' = 'sm'): Promise<Product | boolean> {
        const modalRef = this.modalService.open(CreateProductModalComponent, { size: 'lg', centered: true, scrollable: true, animation: true, modalDialogClass: 'proposer-modal'  });
        modalRef.componentInstance.title = title
        if(category) modalRef.componentInstance.category = category;
        if(product) {
            modalRef.componentInstance.product = product
            modalRef.componentInstance.category = product.category
            modalRef.componentInstance.name = product.name
            modalRef.componentInstance.description = product.description
            modalRef.componentInstance.price1 = product.price1
            modalRef.componentInstance.price1desc = product.price1desc
            modalRef.componentInstance.price2 = product.price2
            modalRef.componentInstance.price2desc = product.price2desc
        }
        if(dialogSize){
          // Todo:
        }
        return modalRef.result;
    }
}
