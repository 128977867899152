import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { DataService } from '../../../../shared/services/data.service';
import { lastValueFrom, Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Product } from 'src/app/proposer/models/product';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-product-model',
  templateUrl: './create-product-modal.component.html',
  styleUrls: ['./create-product-modal.component.css']
})

export class CreateProductModalComponent implements OnInit, OnDestroy {
  @Input() title = "CREATE PRODUCT";
  @Input() btnCancelText = "Cancel";
  @Input() btnOkText = "OK";
  @Input() category!: string

  product!: Product
  name!: string
  description!: string
  price1!: number
  price1desc!: string
  price2!: number
  price2desc!: string
  image!: File
  alert!: string
  isProcessing = false
  ngxCreateProductText!: string;
  ngxNotFill!: string;
  _onLangChangeSub!: Subscription;
  constructor(private activeModal: NgbActiveModal, private _dataService: DataService, private _spinner: NgxSpinnerService, private _translate: TranslateService) {
    this._translate.get("createProduct.requiredFieldsNotFilled").subscribe((value: string) => {
      this.ngxNotFill = value;
    });
    this._translate.get("shared.cancel").subscribe((value: string) => {
      this.btnCancelText = value;
    });
    this._translate.get("shared.ok").subscribe((value: string) => {
      this.btnOkText = value;
    });
  }
  ngOnInit(): void {
    this._onLangChangeSub = this._translate.onLangChange.subscribe(() => {
      this._translate.get("createProduct.requiredFieldsNotFilled").subscribe((value: string) => {
        this.ngxNotFill = value;
      });
      this._translate.get("shared.cancel").subscribe((value: string) => {
        this.btnCancelText = value;
      });
      this._translate.get("shared.ok").subscribe((value: string) => {
        this.btnOkText = value;
      });
    })
  }
  ngOnDestroy(): void {
    if(this._onLangChangeSub){
      this._onLangChangeSub.unsubscribe();
    }
  }

  onImageSelected(event: any) {
    this.image = event.target.files.length == 1? event.target.files[0] : null;
  }

  public decline() {
    this.activeModal.close();
  }

  public save() {
    this.alert = ''
    if(!this._validate()) {
      this.alert = this.ngxNotFill;
      return;
    }
    this._spinner.show('modal-spinner')
    this.isProcessing = true;
    const fd: FormData = new FormData();
    fd.append('file', this.image);
    fd.append('category', this.category);
    fd.append('name', this.name);
    fd.append('description', this.description? this.description : '');
    fd.append('price1', this.price1? this.price1.toString() : '0');
    fd.append('price1desc', this.price1desc? this.price1desc : '');
    fd.append('price2', this.price2? this.price2.toString() : '0');
    fd.append('price2desc', this.price2desc? this.price2desc : '')
    lastValueFrom(this.product? this._dataService.putProduct(fd, this.product._id):this._dataService.createProduct(fd))
      .then(res => {
        this.isProcessing = false;
        this.activeModal.close(res.payload.product);
      }).catch(err => {
        err.message()
        this.isProcessing = false
      }).finally(() => {
        this._spinner.hide('modal-spinner')
      })
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

  private _validate() {
    if(!this.name || (!this.image && !this.product)) {
      return false;
    }
    return true;
  }

  private _showError(statusText: string) {
    this.alert = statusText;
  }
}
