import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProposerModule } from './proposer/proposer.module';
import { PageNotFoundComponent } from './error/page-not-found/page-not-found.component';
import { UnauthorizedComponent } from './error/unauthorized/unauthorized.component';
import { DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { AuthComponent } from './auth/auth.component';
import { AestheticsComponent } from './aesthetics/aesthetics.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MatSliderModule } from '@angular/material/slider';

// registerLocaleData(en);
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    UnauthorizedComponent,
    PdfViewerComponent,
    AuthComponent,
    AestheticsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ProposerModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    PdfJsViewerModule,
    NgxSpinnerModule,
    MatSliderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  bootstrap: [AppComponent],
  providers: [DatePipe]
})
export class AppModule { }
