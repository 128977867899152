import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MeesmaService {

  private _socket!: Socket;
  isAuthenticated = false
  room!: string

  initConnection() {
    this._socket = io(environment.url_be, {});
    this._socket.on("connect", () => {
      // Todo:
    })
  }

  disconnect() {
    this._socket?.disconnect();
  }

  get id() {
    return this._socket.id;
  }

  public on(eventName: string, callback: (data: any) => void): void {
    this._socket.on(eventName, callback);
  }

  public emit(type: string, data: any): void {
    this._socket.emit("message", {
      type: type,
      data: data
    });
  }

  public off(eventName: string): void {
    this._socket.off(eventName);
  }
}

