<div class="modal-header">
    <h4 class="modal-title">{{ title | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="inputfield">
        <label> {{ "createProduct.category" | translate }}</label>
        <input type="text" class="border border-ffgray2 focus:outline-none bg-ffblack h-10 p-2 rounded-lg" [(ngModel)]="category" [disabled]="isProcessing" />
    </div>
    <div class="inputfield">
        <label> {{ "createProduct.nameRequired" | translate }}</label>
        <input type="text" class="border border-ffgray2 focus:outline-none bg-ffblack h-10 p-2 rounded-lg" [(ngModel)]="name" autofocus required [disabled]="isProcessing"/>
    </div>
    <div class="inputfield">
        <label>{{ "createProduct.description" | translate }}</label>
        <textarea class="border border-ffgray2 focus:outline-none bg-ffblack h-20 p-2 rounded-lg" [(ngModel)]="description" [disabled]="isProcessing"></textarea>
    </div>
    <div class="inputfield">
        <label>{{ "createProduct.price1" | translate }}</label>
        <input class="border border-ffgray2 focus:outline-none bg-ffblack h-10 p-2 rounded-lg" type="number" [(ngModel)]="price1" required [disabled]="isProcessing"/>
    </div>
    <div class="inputfield">
        <label>{{ "createProduct.price1Description" | translate }}</label>
        <input class="border border-ffgray2 focus:outline-none bg-ffblack h-10 p-2 rounded-lg" type="text" [(ngModel)]="price1desc" [disabled]="isProcessing"/>
    </div>
    <div class="inputfield">
        <label> {{"createProduct.price2" | translate }}</label>
        <input class="border border-ffgray2 focus:outline-none bg-ffblack h-10 p-2 rounded-lg" type="number" [(ngModel)]="price2" required [disabled]="isProcessing"/>
    </div>
    <div class="inputfield">
        <label> {{"createProduct.price2Description" | translate }}</label>
        <input class="border border-ffgray2 focus:outline-none bg-ffblack h-10 p-2 rounded-lg" type="text" [(ngModel)]="price2desc" [disabled]="isProcessing"/>
    </div>
    <div class="inputfield">
        <label>{{ "createProduct.imageRequired" | translate }}</label>
        <input class="border border-ffgray2 focus:outline-none bg-ffblack h-10 p-2 rounded-lg" type="file" accept="image/*" [disabled]="isProcessing" (change)="onImageSelected($event)"/>
    </div>
    <p *ngIf="alert" style="color: red;">{{alert | translate}}</p>
</div>
<div class="modal-footer" style="position: relative;">
    <ngx-spinner color="rgba(137,54,129,1)" bdColor="#262b31" name="modal-spinner" size="medium" type="ball-clip-rotate" [fullScreen]="false">
    </ngx-spinner>
    <button type="button" class="btn btn-danger" (click)="decline()">{{ btnCancelText | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="save()">{{ btnOkText | translate}}</button>
</div>