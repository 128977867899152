import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {  TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-treatment',
  templateUrl: './create-treatment.component.html',
  styleUrls: ['./create-treatment.component.css']
})
export class CreateTreatmentComponent implements OnInit, OnDestroy{
  treatment!: string
  alertText!: string
  title!: string
  ngxNotEmptyText!: string;
  _onLangChangeSub!: Subscription;
  constructor(private activeModal: NgbActiveModal, private  _translate: TranslateService) {
    this._translate.get("createCategory.shouldNotBeEmpty").subscribe((value: string) => {
      this.ngxNotEmptyText = value;
    })
   }
  ngOnDestroy(): void {
    if(this._onLangChangeSub){
      this._onLangChangeSub.unsubscribe();
    }
  }

  ngOnInit() {
    this._onLangChangeSub = this._translate.onLangChange.subscribe(() => {
      this._translate.get("createCategory.shouldNotBeEmpty").subscribe((value: string) => {
        this.ngxNotEmptyText = value;
      })
    })
  }



  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    if(!this.treatment) {
      this.alertText = this.ngxNotEmptyText;
      return;
    }
    this.alertText = ''
    this.activeModal.close(this.treatment);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
}
