<div class="flex px-5 text-white text-center font-semibold">
    <div class="flex items-center justify-between w-full">
        <ng-container *ngIf="isDrawing">
            <div class="px-3 bg-black rounded-lg py-2" style="cursor: pointer;" (click)="onSaveBack()">
                {{ 'bottomBar.backToClientType' | translate: { clientType: clientType.toUpperCase() } }}
            </div>
            <div *ngIf="canUseRecommendation" class="px-3 bg-ffred rounded-lg py-2" style="cursor: pointer;" (click)="onToRecommendation()">
                {{"bottomBar.proceedToRecommendation" | translate }}
            </div>
        </ng-container>
        <ng-container *ngIf="isRecommend">
            <div class="px-3 bg-black rounded-lg py-2" style="cursor: pointer;" (click)="onToDrawings()">
                {{ "bottomBar.backToDrawingTools" | translate }}
            </div>
            <div *ngIf="!isPrint" class="px-3 bg-ffred rounded-lg py-2" style="cursor: pointer;" (click)="onMoveToPrint()">
                {{ "bottomBar.proceedToReporting" | translate }}
            </div>
        </ng-container>
        <ng-container *ngIf="isFace">
            <div class="px-3 bg-black rounded-lg py-2" style="cursor: pointer;" (click)="onToDrawings()">
                {{ "bottomBar.backToDrawingTools" | translate }}
            </div>
            <div *ngIf="!isPrint" class="px-3 bg-ffred rounded-lg py-2" style="cursor: pointer;" (click)="onSaveToDrawings()">
                {{ "bottomBar.saveToDrawingTools" | translate }}
            </div>
        </ng-container>
    </div>
</div>