import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { ClientType } from 'src/app/utils/const';
import { MeesmaService } from 'src/app/shared/services/meesma.service';

export const ProposerGuardCanActivate = async (route: ActivatedRouteSnapshot) => {
  const router = inject(Router)
  const meesmaService = inject(MeesmaService)
  const authService = inject(AuthService)
  const client = sessionStorage.getItem("client") || "hub"
  if(client != ClientType.MEESMA && !route.queryParams['uuid'] ) {
    return false
  } 
  if((client == ClientType.MEESMA && meesmaService.isAuthenticated)
    || authService.isAuthenticated) return true
  else {
    await router.navigate(['/proposer'], {
      queryParams: route.queryParams,
      replaceUrl: true
    })
    return false
  }
}


