<div class="modal-header">
    <h4 class="modal-title"> {{ "createCategory.createCategory" | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="inputfield">
        <label> {{ "createCategory.enterCategoryName" | translate }}</label>
        <input type="text" [(ngModel)]="category" required focus class="border border-ffgray2 focus:outline-none bg-ffblack h-10 p-2 rounded-lg" />
        <p *ngIf="alertText" style="color: red;">{{alertText | translate}}</p>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="decline()"> {{ "shared.cancel" | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="accept()"> {{ "shared.save" | translate }}</button>
</div>