import { FaceSimulationService } from '../../services/face-simulation.service';
import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { UserActionService } from 'src/app/shared/services/user-action.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from 'src/app/shared/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/shared/services/app.service';
import { ClientType } from 'src/app/utils/const';

@Component({
  selector: 'app-face-simulation',
  templateUrl: './face-simulation.component.html',
  styleUrls: ['./face-simulation.component.css']
})
export class FaceSimulationComponent implements OnInit, OnDestroy{
  message = '';
  url: string | ArrayBuffer | null = '';
  images: any;
  dataUrl: string | null | undefined = null;
  spin = false;
  private _mode = -1;
  onVertical = false;
  onHorizontal = false;
  onMesh = false;
  sliderLeft = 50
  onOriginalImageSlider = false
  private _isSliding = false


  // private _sliderValue: number = 0;
  _upperVal = 0;
  _lowerVal = 0;
  _eyesBrowVal = 0;
  sliderVisible = false;
  upper = false;
  isEnabled = false;
  isAnalyzed = false;
  _onLangChangeSub!: Subscription;
  translations: {[key: string]: string} = {}
  client!: string

  @Output() imageDataChange = new EventEmitter<string>();
  @ViewChild('targetImage') targetImage!: ElementRef;
  @ViewChild('sliderThumbUpper') sliderThumbUpper!: ElementRef;
  @ViewChild('sliderThumbLower') sliderThumbLower!: ElementRef;
  @ViewChild('sliderForEyesBrow') sliderForEyesBrow!: ElementRef;
  @ViewChild('outputDiv') outputDiv!: ElementRef;
  @ViewChild('canvasOutput') canvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasBound') canvasBounds!: ElementRef<HTMLDivElement>


  constructor(
    private _faceSimulationService: FaceSimulationService, private _userActionService: UserActionService,
    private _spinner: NgxSpinnerService, private _modalService: ModalService, private _translate: TranslateService,
    private _appService: AppService
  ) {
    this._translate.get(["faceSimulation.waitForApplicationToLoad",
      "faceSimulation.unableToDetectFace"])
      .subscribe((value) => {
        this.translations = value
      })
  }
  ngOnDestroy(): void {
    if(this._onLangChangeSub){
      this._onLangChangeSub.unsubscribe()
    }
  }

  ngOnInit(): void {
    this.client = this._appService.getClientType();
    this._userActionService.replaceImage$.subscribe(() => {
      this._replaceImage();
    });
    this._onLangChangeSub = this._translate.onLangChange.subscribe(() => {
      this._translate.get(["faceSimulation.waitForApplicationToLoad", "faceSimulation.unableToDetectFace"])
        .subscribe((value) => {
          this.translations = value
        })
    })
    this. _userActionService.faceImage$.subscribe(data => {
      if(this.isAnalyzed) return;
      this._spinner.show("face-load")
      this.targetImage.nativeElement.onload = () => {
        this._faceSimulationService.uploadImageToCanvas().then((status) => {
          this._spinner.hide("face-load")
          switch(status) {
            case -1:
              this._modalService.openNotification("faceSimulation.waitForApplicationToLoad").finally(() => {
                this._userActionService.announceBackDrawings(0);
              })
              break;
            case -2:
              this._modalService.openNotification("faceSimulation.unableToDetectFace")
                .finally(() => {
                  this._userActionService.announceLockFace(0);
                  this._userActionService.announceBackDrawings(0);
                });
              break;
            default:
              this.isEnabled = true;
              this.isAnalyzed = true;
          }
        });
      }
      this.url = data;
      this.sliderVisible = true;
    });
  }

  async onVerticalClick() {
    this.spin = true
    if (this._mode === 2) {
      this._mode = 0;
    }
    this.turnOff()

    this.onVertical = !this.onVertical;
    if(!this.onVertical){
      this.turnOff()
      this.spin = false;
      return
    }
    // await this._faceSimulationService.uploadImageToCanvas()
    this._faceSimulationService.showVerticalDividers();
    this._mode = this._mode === 1 ? 0 : 1;
    this.spin = false
    this.onHorizontal = false
    this.onMesh = false
  }

  async onHorizontalClick() {
    this.spin = true
    if (this._mode === 1) {
      this._mode = 0;
    }
    this.turnOff()

    this.onHorizontal = !this.onHorizontal;
    if(!this.onHorizontal){
      this.turnOff()
      this.spin = false;
      return
    }
    this._faceSimulationService.showHorizontalDividers();
    this._mode = this._mode === 2 ? 0 : 2;
    this.spin = false
    this.onMesh = false
    this.onVertical = false
  }


  async onFileChanged(event: any) {
    // this._faceSimulationService.removeAllCanvas(this.targetImage.nativeElement);
    // this._faceSimulationService.resetCtx2DCanvas();
    this.spin = true
    this._mode = -1;
    const files = event.target.files;
    if (!files || files.length === 0) {
      this.spin = false;
      return;
    }

    // đang cho đọc nhiều files ??
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }
    const reader = new FileReader();
    this.images = files;
    reader.readAsDataURL(files[0]);
    this.targetImage.nativeElement.onload = () => {
      this._faceSimulationService.uploadImageToCanvas().then(() => console.log("Done"));
    }
    reader.onload = () => {
      this.url = reader.result;
    };
    this.sliderVisible = true;
    this.spin = false
  }

  onDownload() {
    this.dataUrl = this._faceSimulationService.downloadCanvas();
  }

  async onDrawFaceMesh() {
    this.spin = true;
    this.onMesh = !this.onMesh;
    if(!this.onMesh){
      this.turnOff()
      this.spin = false;
      return
    }
    await this._faceSimulationService
      .drawFaceMesh()
      .then(() => console.log('Success!'))
      .catch((error: Error) => console.log(error.message))
      .finally(() => (this.spin = false));
    this.onVertical = false
    this.onHorizontal = false
  }


  // Xử lý môi
  async onLiftLips(upper: boolean) {
    this.spin = true;
    this.sliderVisible = this.url != '';
    this.upper = upper;
    console.log("if it's called here again")
    await this._faceSimulationService
      .updatePreState(upper)
      .then(() => console.log('Success!'))
      .catch((error: Error) => console.log(error.message))
      .finally(() => (this.spin = false));
    await this._faceSimulationService
      .liftLips(this.targetImage.nativeElement, upper ? this._upperVal : this._lowerVal, upper)
      .then(() => console.log('Success!'))
      .catch((error: Error) => console.log(error.stack))
      .finally(() => (this.spin = false));
  }

  async onValueChangedUpper() {

    this.spin = true
    console.log('value uppler lip' + this._upperVal)
    await this._faceSimulationService
      .updatePreState(true)
      .then(() => console.log('Success!'))
      .catch((error: Error) => console.log(error.message))
      .finally(() => (this.spin = false));
    await this._faceSimulationService
      .liftLips(this.targetImage.nativeElement, this._upperVal, true)
      .then(() => console.log('Success!'))
      .catch((error: Error) => console.log(error.message))
      .finally(() => (this.spin = false));
  }

  async onValueChangedLower() {

    this.spin = true
    await this._faceSimulationService
      .updatePreState(false)
      .then(() => console.log('Success!'))
      .catch((error: Error) => console.log(error.message))
      .finally(() => (this.spin = false));
    await this._faceSimulationService
      .liftLips(this.targetImage.nativeElement, this._lowerVal, false)
      .then(() => console.log('Success!'))
      .catch((error: Error) => console.log(error.message))
      .finally(() => (this.spin = false));
  }



  // Xử lý lông mày
  async onLiftEyesBrow() {
    this.spin = true;
    this.sliderVisible = this.url != '';

    await this._faceSimulationService
      .liftEyesBrow(this._eyesBrowVal)
      .then(() => console.log('Success!'))
      .catch((error: Error) => console.log(error.message))
      .finally(() => (this.spin = false));
  }

  async onValueChangedForEyesBrow() {
    this.spin = true

    await this._faceSimulationService
      .liftEyesBrow(this._eyesBrowVal)
      .then(() => console.log('Success!'))
      .catch((error: Error) => console.log(error.message))
      .finally(() => (this.spin = false));
  }


  formatSliderValue() {
    return 'Custom Value' // hoặc bất kỳ định dạng nào phù hợp với bạn
  }

  async resetImg(){
    this.spin = true
    await this._faceSimulationService
      .resetImg()
      .then(() => console.log('Success!'))
      .catch((error: Error) => console.log(error.message))
      .finally(() => (this.spin = false));
    this._lowerVal = 0
    this._eyesBrowVal = 0
    this._upperVal = 0
    this.onVertical = false
    this.onHorizontal = false
    this.onMesh = false
  }
  async resetImgForLifting(){
    this.spin = true
    await this._faceSimulationService
      .resetImg()
      .then(() => console.log('Success!'))
      .catch((error: Error) => console.log(error.message))
      .finally(() => (this.spin = false));

  }
  async turnOff(){
    this.spin = true;
    this._faceSimulationService.turnOffUpperCanvas()
  }

  // private methods
  private _replaceImage() {
    const {lowerUrl, originalImageUrl, upperUrl, imageWidth, imageHeight} = this._faceSimulationService.getImageFromCanvas();
    if(upperUrl == null && originalImageUrl == null) {
      this.imageDataChange.emit(lowerUrl)
      this._spinner.hide('filter-spinner');
      return;
    }
    const tempCanvas = document.createElement('canvas')
    tempCanvas.setAttribute('width', `${imageWidth}px`)
    tempCanvas.setAttribute('height',`${imageHeight}px`)
    // document.body.appendChild(tempCanvas)
    const tempCtx = tempCanvas.getContext('2d')
    const lowerImage = new Image();
    const upperImage = new Image();
    const originImage = new Image();
    if(upperUrl){
      upperImage.onload = () => {
        tempCtx?.drawImage(upperImage, 0, 0)
        const url = tempCanvas.toDataURL('image/jpg')
        this.imageDataChange.emit(url)
        // tempCanvas.remove()
      }
    }


    if(originalImageUrl){
      originImage.onload = () => {
        tempCtx?.drawImage(originImage, 0, 0, originImage.naturalWidth, originImage.naturalHeight)
        if(upperUrl){
          upperImage.src = upperUrl
        }else{
          const url = tempCanvas.toDataURL('image/jpg')
          this.imageDataChange.emit(url)
        }
      }
    }

    lowerImage.onload = () => {
      tempCtx?.drawImage(lowerImage, 0, 0, lowerImage.naturalWidth, lowerImage.naturalHeight)
      if(originalImageUrl){
        originImage.src = originalImageUrl
      }else if(upperUrl) {
        upperImage.src = upperUrl
      }
    }
    lowerImage.src = lowerUrl
  }

  onSliderMouseDown(ev: MouseEvent): void {
    ev.preventDefault();
    this._isSliding = true;
  }

  onSliderTouchStart(ev: TouchEvent): void {
    ev.preventDefault();
    this._isSliding = true;
  }

  onContainerTouchEnd(): void {
    //ev.preventDefault();
    this._isSliding = false;
  }

  onContainerTouchMove(ev: TouchEvent): void {
    if (!this._isSliding) return;
    if (ev.touches.length > 1) return;
    const offsetX = ev.touches[0].clientX - this.canvasBounds.nativeElement.offsetLeft;
    this.sliderLeft = offsetX > 0 ? offsetX * 100 / this.canvasBounds.nativeElement.offsetWidth : 0;
    if (this.sliderLeft > 100) this.sliderLeft = 100
    ev.stopPropagation();
  }

  onContainerMouseUp(ev: MouseEvent): void {
    ev.preventDefault();
    this._isSliding = false;
  }

  onContainerMouseLeave(ev: MouseEvent): void {
    ev.preventDefault();
    this._isSliding = false;
  }

  onContainerMouseMove(ev: any): void {
    if (!this._isSliding) return;
    const rect = ev.currentTarget.getBoundingClientRect();
    const offsetX = ev.clientX - rect.left;
    this.sliderLeft = offsetX > 0 ? offsetX * 100 / rect.width : 0;
    this._faceSimulationService.showOriginalImage(this.sliderLeft)
  }
  handleOriginalImageSlider(){
    this.onOriginalImageSlider = !this.onOriginalImageSlider
    this._faceSimulationService.handleOnOriginalImageSlider(this.onOriginalImageSlider)
    this._faceSimulationService.showOriginalImage(this.sliderLeft)
  }

  get canShowSlider() {
    return this._lowerVal != 0 || this._upperVal != 0 || this._eyesBrowVal != 0;
  }

  get isHub() {
    return this.client === ClientType.HUB;
  }
}
