import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.css']
})
export class CreateCategoryComponent implements OnInit, OnDestroy {

  category!: string
  alertText!: string
  ngxNotEmptyText!: string;
  _onLangChangeSub!: Subscription;
  constructor(private activeModal: NgbActiveModal, private _translate: TranslateService) {
    this._translate.get('createCategory.shouldNotBeEmpty').subscribe((value: string) => {
      this.ngxNotEmptyText = value;
    })
   }
  ngOnDestroy(): void {
    if(this._onLangChangeSub) {
      this._onLangChangeSub.unsubscribe();
    }
  }

  ngOnInit() {
    this._onLangChangeSub = this._translate.onLangChange.subscribe(() => {
      this._translate.get('createCategory.shouldNotBeEmpty').subscribe((value: string) => {
        this.ngxNotEmptyText = value;
      })
    })
  }



  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    if(!this.category) {
      this.alertText =  this.ngxNotEmptyText
      return;
    }
    this.alertText = ''
    this.activeModal.close(this.category);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
}
