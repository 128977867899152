import { fabric } from 'fabric';
export const Fabric: any = fabric;

Fabric.Cross = fabric.util.createClass(fabric.Group, {
    type: 'cross',

    initialize: function (objs: fabric.Object[], options?: any, isAlreadyGrouped?: boolean) {
        const objects: fabric.Object[] = objs || [];
        if (!objects.length) {
            const l1 = new fabric.Line([0, options.radius - options.lineWidth / 2, options.radius * 2, options.radius - options.lineWidth / 2], {
                stroke: options.stroke,
                strokeWidth: options.lineWidth
            });
            const l2 = new fabric.Line([options.radius - options.lineWidth / 2, 0, options.radius - options.lineWidth / 2, options.radius * 2], {
                stroke: options.stroke,
                strokeWidth: options.lineWidth
            });
            const text = new fabric.Text(options.text || '', {
                left: options.radius * 2.5,
                top: options.radius * 2.5,
                fontSize: options.fontSize,
                fill: options.fill
            })
            objects.push(l1);
            objects.push(l2);
            objects.push(text);
        }
        this.callSuper('initialize', objects, options, isAlreadyGrouped);
    }
})

Fabric.Cross.fromObject = function (object: any, callback: any) {
    const objects = object.objects,
        options = fabric.util.object.clone(object);
    delete options.objects;
    if (typeof objects === 'string') {
        // it has to be an url or something went wrong.
        fabric.loadSVGFromURL(objects, function (elements) {
            const group = fabric.util.groupSVGElements(elements, object, objects);
            const clipPath = options.clipPath;
            delete options.clipPath;
            group.set(options);
            if (clipPath) {
                fabric.util.enlivenObjects([clipPath], function (elivenedObjects: any) {
                    group.clipPath = elivenedObjects[0];
                    callback && callback(group);
                }, 'fabric');
            }
            else {
                callback && callback(group);
            }
        });
        return;
    }
    fabric.util.enlivenObjects(objects, function (enlivenedObjects: any) {
        callback && callback(new Fabric.Cross(enlivenedObjects, options, true));
    }, 'fabric');
};

Fabric.TPoint = fabric.util.createClass(fabric.Group, {
    type: 'point',

    initialize: function (objs: fabric.Object[], options?: any, isAlreadyGrouped?: boolean) {
        const objects: fabric.Object[] = objs || [];
        if (!objects.length) {
            const p = new fabric.Circle({
                left: 0,
                top: 0,
                fill: options.fill,
                radius: options.radius
            });
            const text = new fabric.Text(options.text || '', {
                left: options.radius * 2.5,
                top: options.radius * 2.5,
                fontSize: options.fontSize,
                fill: options.fill
            })
            objects.push(p);
            objects.push(text);
        }
        this.callSuper('initialize', objects, options, isAlreadyGrouped);
    }
})

Fabric.TPoint.fromObject = function (object: any, callback: any) {
    const objects = object.objects,
        options = fabric.util.object.clone(object);
    delete options.objects;
    if (typeof objects === 'string') {
        // it has to be an url or something went wrong.
        fabric.loadSVGFromURL(objects, function (elements) {
            const group = fabric.util.groupSVGElements(elements, object, objects);
            const clipPath = options.clipPath;
            delete options.clipPath;
            group.set(options);
            if (clipPath) {
                fabric.util.enlivenObjects([clipPath], function (elivenedObjects: any) {
                    group.clipPath = elivenedObjects[0];
                    callback && callback(group);
                }, 'fabric');
            }
            else {
                callback && callback(group);
            }
        });
        return;
    }
    fabric.util.enlivenObjects(objects, function (enlivenedObjects: any) {
        callback && callback(new Fabric.TPoint(enlivenedObjects, options, true));
    }, 'fabric');
};


