<div class="flex items-center justify-center grow relative portrait:pb-2 portrait:w-full portrait:h-1/4">
    <img [src]="imageData" alt="" class="absolute object-contain max-h-full h-full">
</div>
<ngx-spinner 
    color="rgba(137,54,129,1)"
    bdColor="rgba(51,51,51,0.8)"
    type="ball-clip-rotate"
    [fullScreen]="true"
    name="report-spinner"
    >
    <p style="font-size: 20px;">{{ "recommendation.loadingMessage" | translate }}</p>
</ngx-spinner>
<div class="w-1/2 shrink-0 flex gap-2 portrait:h-3/4 portrait:grow">
    <div class="w-1/2 flex flex-col gap-2">
        <div class="bg-ffgray1 flex items-center justify-between px-2 py-1 shrink-0">
            <div class="flex items-center"> {{ "recommendation.products" | translate }} </div>
            <div class="flex items-center gap-2">
                <i matTooltip="Refresh" matTooltipPosition="above" matTooltipShowDelay="200" class="fa-solid fa-arrows-rotate icon-add-product" (click)="refreshComponent()"></i>
                <i matTooltip="Edit" matTooltipPosition="above" matTooltipShowDelay="200" class="fa-sharp fa-solid fa-pencil" (click)="onToggleEditingMode()"></i>
            </div>              
        </div>
        <div class="bg-ffgray1 flex items-center justify-between px-2 py-2 grow overflow-y-auto">
            <div class="bg-ffgray w-full h-full relative p-1 flex flex-col gap-1 text-xs font-semibold overflow-y-auto">
                <ng-container *ngFor="let category of categoryList">
                    <div class="bg-ffgray1 px-1 py-0.5 cursor-pointer" [class.selected]="category.selected" (click)="onProductTypeSelected($event, category)">
                        <!-- <i class="fa-solid fa-xmark" style="margin-left: -5px;" *ngIf="isProductEditing" (click)="deleteCategory($event, category)"></i> -->
                        {{category.category}}
                        <!-- <i class="fa-sharp fa-solid fa-pencil" *ngIf="isProductEditing" (click)="onEditCategory($event, category)"></i> -->
                    </div>
                    <img hidden *ngFor="let p of category.products" [src]="p.url" alt="">
                    <div class="flex flex-col gap-1 pl-3 py-1" *ngIf="category.selected" >
                        <ng-container *ngFor="let p of category.products, index as i">
                            <div class="flex" *ngIf="isShowDefault || !p.isDefault">
                                <i class="fa-solid fa-xmark icon" *ngIf="isProductEditing" (click)="deleteProduct($event, p)"></i>
                                <div class="grow bg-ffgray1 px-1 py-0.5 cursor-pointer" [class.selected]="p.selected" (click)="onProductClicked($event, p)">{{p.name}}{{(p.isDefault?' (Default)':'')}}</div>
                                <i class="fa-sharp fa-solid fa-plus icon" (click)="onProductSelected($event, p)" *ngIf="!isProductEditing"></i>
                                <i class="fa-sharp fa-solid fa-pencil icon" *ngIf="isProductEditing" (click)="onEditProduct($event, p)"></i>
                            </div>
                        </ng-container>
                        <div *ngIf="isProductEditing" class="icon" (click)="onCreateProduct($event, category.category)">
                            <i class="fa-solid fa-sharp fa-plus text-black"></i>
                        </div>
                    </div>
                </ng-container>
                <div *ngIf="isProductEditing" class="icon" (click)="onCreateCategory()">
                    <i class="fa-solid fa-sharp fa-plus text-black font-bold"></i>
                </div>
                <ngx-spinner color="rgba(137,54,129,1)" bdColor="#262b31" name="product-spinner" size="medium" type="ball-clip-rotate" [fullScreen]="false">
                </ngx-spinner>
                <!-- <button type="button" class="btn btn-sm" (click)="onToggleShowDefault()">{{isShowDefault? 'HIDE' : 'SHOW'}} DEFAULT</button> -->
            </div>
        </div>
    </div>
    <div class="w-1/2 flex flex-col">
        <div class="h-2/3 shrink-0 flex flex-col gap-2 pb-1">
            <div class="bg-ffgray1 flex items-center justify-between px-2 py-1 shrink-0">
                <div class="flex items-center">{{ "recommendation.preview" | translate }}</div>
            </div>
            <div class="bg-ffgray1 flex items-center justify-between px-2 py-2 grow">
                <div class="bg-ffgray w-full h-full relative p-1 flex flex-col gap-1 text-xs font-semibold overflow-y-auto">
                    <ng-container *ngIf="reviewingProduct">
                        <div class="">{{reviewingProduct.name}}</div>
                        <div class="relative grow flex items-center justify-center min-h-[50%]">
                            <img [src]="reviewingProduct.url || 'assets/img/notfound.png'" class="absolute object-contain max-h-full" (load)="onPreviewImageLoaded($event)">
                            <ngx-spinner color="rgba(137,54,129,1)" bdColor="#262b31" name="review-spinner" size="medium" type="ball-clip-rotate" [fullScreen]="false">
                            </ngx-spinner>
                        </div>
                        <div style="word-break: break-word;">{{reviewingProduct.description | translate}}</div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="grow flex flex-col gap-2 pt-1 overflow-y-auto">
            <div class="bg-ffgray1 flex items-center justify-between px-2 py-1 shrink-0">
                <div class="flex items-center">{{ "recommendation.selected" | translate }}</div>
            </div>
            <div class="bg-ffgray1 flex items-center justify-between px-2 py-2 grow">
                <div class="bg-ffgray w-full h-full relative p-1 flex flex-col gap-1 text-xs font-semibold overflow-y-auto">
                    <div class="grow flex flex-col gap-1">
                        <ng-container *ngFor="let p of productList">
                            <!-- <ng-container *ngFor="let p of category."> -->
                            <div *ngIf="p.selected" class="bg-ffgray1 flex">
                                <div class="grow bg-ffgray1 px-1 py-0.5 cursor-pointer" (click)="onProductClicked($event, p)" [class.selected]="p.selected">{{p.name}}</div>
                                <i class="fa-sharp fa-solid fa-minus icon" (click)="onProductDeselected($event, p)"></i>
                            </div>
                            <!-- </ng-container> -->
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="w-auto shrink-0 flex flex-col gap-2" *ngIf="isPrint">
    <div class="bg-ffgray1 flex items-center justify-between px-2 py-1 gap-2">
        <div class="flex items-center">{{ "recommendation.selectPrintLayout" | translate }}</div>
        <i class="fa-sharp fa-solid fa-xmark cursor-pointer" (click)="onPrintClosed()"></i>
    </div>
    <div class="bg-ffgray1 flex items-center justify-between px-2 py-2 grow">
        <div class="bg-ffgray w-full h-full relative p-1 flex flex-col gap-2 text-xs font-semibold">
            <div *ngFor="let layout of reportLayouts; index as index" class="grow flex justify-center items-center relative report-image">
                <img [matTooltip]="layout.tooltip | translate" [matTooltipPosition]="'after'" class="object-contain absolute max-h-full cursor-pointer"
                    matTooltipPositionAtOrigin="true" src="assets/report/{{layout.path}}" alt="{{layout.tooltip | translate}}" (click)="onCreateReport($event, index)">
            </div>
        </div>
    </div>
</div>
