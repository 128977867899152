import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslationMessagesService {

  constructor(
    private translate: TranslateService,
  ){

  }
  private modeMessagesSource = new Subject<string>();

  modeMessages$ = this.modeMessagesSource.asObservable();

  sendModeMessages(langCode: string){
    this.modeMessagesSource.next(langCode);
  }

  changeLanguage(language: any): void {
    localStorage.setItem('language', language.locale.locale);
    this.translate.use(language.locale.locale);
  }
}
