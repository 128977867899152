import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../shared/services/auth.service';
import { lastValueFrom, Subscription } from 'rxjs';
import { ClientType, MessageType } from '../utils/const';
import { MeesmaService } from '../shared/services/meesma.service';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../shared/services/app.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements AfterViewInit, OnInit, OnDestroy {

  isAuthenticating = true
  loadingText = ''
  ngxCheckingString!: string;
  ngxMovingString!: string;
  _onLangChangeSub!: Subscription;
  constructor(private _spinner: NgxSpinnerService, private _route: ActivatedRoute,
  private _router: Router, private _authService: AuthService, private _meesmaService: MeesmaService,
  private _translate: TranslateService, private router: Router, private _appService: AppService) {
  }

  ngOnInit(): void {
    this._onLangChangeSub = this._translate.onLangChange.subscribe(() => {
      this._translate.get(["auth.movingToProposer","auth.checkingAuthentication"]).subscribe((translations) => {
        this.ngxMovingString = translations["auth.movingToProposer"];
        this.ngxCheckingString = translations["auth.checkingAuthentication"];
      });
    })
  }

  ngOnDestroy(): void {
    if(this._onLangChangeSub){
      this._onLangChangeSub.unsubscribe()
    }
  }

  ngAfterViewInit(): void {
    this._translate.get(["auth.movingToProposer","auth.checkingAuthentication"]).subscribe((translations) => {
      this.ngxMovingString = translations["auth.movingToProposer"];
      this.ngxCheckingString = translations["auth.checkingAuthentication"];
      this._authenticate();
    });
  }

  private async _authenticate() {
    this._spinner.show('auth-spinner')
    this.loadingText = this.ngxCheckingString;
    const queryParams = this._route.snapshot.queryParams
    const client = queryParams['client']
    if(client == ClientType.MEESMA) {
      this._authMeesma(queryParams);
    } else {
      this._authHub(queryParams);
    }
  }

  private _joinedCallback(room: any, data: any) {
    if(data.id != this._meesmaService.id) {
      this._router.navigate(['/unauthorized'], {
        replaceUrl: true
      })
    } else {
      this._meesmaService.room = room;
      sessionStorage.setItem("room", room);
      sessionStorage.setItem("client", ClientType.MEESMA);
      this._meesmaService.isAuthenticated = true;
      this.loadingText = this.ngxMovingString;
      this._appService.setClientType(ClientType.MEESMA);
      setTimeout(() => {
        this._router.navigate(['/proposer/recommendation'], {
          queryParams: {
          },
          replaceUrl: true
        })
      }, 1000)
    }
  }

  private async _authMeesma(queryParams: Params) {
    this._meesmaService.initConnection();
    const room = queryParams['room_id'] || sessionStorage.getItem("room");
    this._meesmaService.emit(MessageType.JOIN, { room: room });
    this._meesmaService.on("message", (msg) => {
      if(msg.type != MessageType.JOINED) return;
      this._meesmaService.off("message");
      this._joinedCallback(room, msg.data);
    })
  }

  private async _authHub(queryParams: Params) {
    const uuid = queryParams['uuid']
    const sec = queryParams['sec']
    if(!uuid) {
      this._router.navigate(['/unauthorized'], {
        replaceUrl: true
      })
    } else if(sec) {
      try {
        const token = (await lastValueFrom(this._authService.authHub(sec))).access_token
        sessionStorage.setItem('hub-token', token)
      } catch (err) {
        sessionStorage.removeItem('hub-token')
        this._router.navigate(['/unauthorized'], {
          replaceUrl: true
        })
        return
      }
    }
    const token = sessionStorage.getItem('hub-token')
    if(!token) {
      this._router.navigate(['/unauthorized'], {
        replaceUrl: true
      })
    } else {
      try {
        await lastValueFrom(this._authService.authProposer(token))
        this._authService.isAuthenticated = true
        this.loadingText = this.ngxMovingString;
        sessionStorage.setItem("client", ClientType.HUB);
        this._appService.setClientType(ClientType.HUB);
        setTimeout(() => {
          this._router.navigate(['/proposer/recommendation'], {
            queryParams: {uuid: uuid},
            replaceUrl: true
          })
        }, 1000)
      } catch (err) {
        this._router.navigate(['/unauthorized'], {
          replaceUrl: true
        })
      }
    }
  }
}
