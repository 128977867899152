<app-topbar [isDrawing]="isDrawing"></app-topbar>
<app-image-editor (faceSimulation)="onFaceSimulation()" (retry)="onReloadImage()" [uuid]="uuid"
  [imageData]="imageData"  [(filterMode)]="currentFilter" [style.display]="isDrawing? 'grid':'none'" #imageEditor>
</app-image-editor>
<app-recommendation *ngIf="isHub" [style.display]="isRecommend? 'flex':'none'" [(isPrint)]="isPrint" [image]="image" 
  [imageData]="croppedImageData" (closePrint)="onPrintClosed()">
</app-recommendation>
<app-face-simulation [style.display]="isFace? 'flex':'none'" [(imageData)]="imageData">
</app-face-simulation>
<app-bottombar [isCropSuccess]="croppedImageData || imageEditor.isImageLoaded? true: false" 
  (saveBack)="onSaveBack()" [(isFace)]="isFace" [(isRecommend)]="isRecommend" [(isDrawing)]="isDrawing"
  [(isPrint)]="isPrint" [clientType]="client">
</app-bottombar>
